<template>
	<AgentProfile :pages="pages" :slug="$route.params.slug" />
</template>

<script>
import AgentProfile from '@/modules/broker/pages/Profile/BrokerProfile.vue'

export default {
	components: {
		AgentProfile
	},
	computed: {
		pages() {
			return {
				first: {
					title: this.$t('general.home'),
					srcName: 'MainRoute'
				},
				inner: [
					{
						title: this.$t('web.agency_profile_title'),
						srcName: 'BrokerProfile'
					}
				],
				current: this.$t('web.pages_change_agent_profile')
			}
		}
	}
}
</script>
